const blockchairCoinNames = {
  BTC: "bitcoin",
  ETH: "ethereum",
  XRP: "ripple",
  LTC: "litecoin",
  DOGE: "dogecoin"
};

const blockchairCoinsArray = Object.keys(blockchairCoinNames);
const etherscanCoinsArray = [
  "USDT",
  "LINK",
  "UNI",
  "SUSHI",
  "CHZ",
  "1INCH",
  "STORJ",
  "VET",
  "ZIL"
];

const getBlockchairTxUrl = (coinCode, txhash) => {
  const coinName = blockchairCoinNames[coinCode];
  return `https://blockchair.com/${coinName}/transaction/${txhash}`;
};

const getEtherscanTxUrl = txhash => {
  return `https://etherscan.io/tx/${txhash}`;
};

const getTronscanTxUrl = txhash => {
  return `https://tronscan.org/#/transaction/${txhash}`;
};

const getTokenviewTxUrl = txhash => {
  return `https://usdt.tokenview.io/en/tx/${txhash}`;
};

export const getTxUrl = (coinCode, txhash) => {
  const coinToUpperCase = coinCode.toUpperCase();
  if (blockchairCoinsArray.includes(coinToUpperCase)) {
    return getBlockchairTxUrl(coinCode, txhash);
  }
  if (etherscanCoinsArray.includes(coinToUpperCase)) {
    return getEtherscanTxUrl(txhash);
  }
  if (coinToUpperCase === "TRX") {
    return getTronscanTxUrl(txhash);
  }
  if (coinToUpperCase === "USDTTRC") {
    return getTokenviewTxUrl(txhash);
  }
  return null;
};
