import i18n from "@/plugins/vue-i18n";

export const getOrderBadgeAndLabel = (status, isPayment = false) => {
  let itemClass = null;
  let itemLabel = null;

  const labelRoot = "PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES";

  const orderItemClasses = [
    "badge-warning",
    "badge-success",
    "badge-info",
    "badge-danger",
    "badge-danger",
    "badge-danger",
    "badge-danger"
  ];

  const paymentItemClasses = [
    "badge-warning",
    "badge-info",
    "badge-success",
    "badge-danger",
    "badge-danger",
    "badge-danger",
    "badge-danger"
  ];

  const itemClasses = isPayment ? paymentItemClasses : orderItemClasses;

  itemClass = itemClasses[status];
  itemLabel = i18n.t(`${labelRoot}[${status}]`);

  return { itemClass, itemLabel };
};

export const getTotal = order => {
  const price = parseFloat(order.price);
  const fee = parseFloat(order.fee);
  const isFeeIncludedPrice = order.feeIncluded;

  const feeAmount = (price * fee) / 100;
  const totalAmount = isFeeIncludedPrice
    ? price - feeAmount
    : price + feeAmount;
  return totalAmount.toFixed(2);
};

export const getPaymentUnitName = order => {
  const signs = { USD: "$", GBP: "£", TRY: "₺", EUR: "€" };

  // const isCompleted = +order?.status === 1;
  // const paymentUnitName = signs[order?.paymentUnitName];
  const paymentUnitName = order?.paymentUnitName
    ? signs[order.paymentUnitName.toUpperCase()]
    : "";
  return paymentUnitName;
  // return isCompleted && paymentUnitName ? paymentUnitName : "";
};

export const getPaidCryptoQuantity = order => {
  return [1, 5].includes(order.status)
    ? order.platform === "Other"
      ? order.nexusAmount
      : order.amount
    : "";
};

export const getPlatform = item => {
  return item.platform
    ? `${i18n.t(`devices.${item.deviceShort}`)} - ${item.platform}`
    : "";
};
