<template>
  <span
    :class="[
      'd-inline-flex flex-column align-items-center',
      { 'ml-4': filterIndex > 0 }
    ]"
  >
    <label>{{ $te(filter.text) ? $t(filter.text) : filter.text }}</label>
    <b-dropdown
      right
      size="sm"
      :text="getButtonText()"
      variant="outline-primary"
    >
      <b-dropdown-item-button
        @click="$emit('option-selected', filterIndex, option.value)"
        v-for="(option, optionIndex) in options"
        :key="optionIndex"
      >
        <span class="d-inline-flex align-items-center">
          <b-icon
            v-if="isOptionActive(option.value)"
            icon="check"
            font-scale="1.5"
            class="mr-1"
            variant="success"
          ></b-icon>
          {{ $te(option.text) ? $t(option.text) : option.text }}
        </span>
      </b-dropdown-item-button>
    </b-dropdown>
  </span>
</template>

<script>
import { FILTER_TYPE, PAYMENT_STATE, ORDER_STATUS } from "@/constants";

export default {
  name: "ListFilter",
  props: ["filter", "filterIndex"],
  data() {
    return {
      regularOptions: [
        {
          text: "FORM.YES",
          value: 1
        },
        {
          text: "FORM.NO",
          value: 0
        }
      ],
      allOption: {
        text: "ECOMMERCE.COMMON.ALL",
        value: undefined
      }
    };
  },
  computed: {
    options() {
      let returnOptions = [];
      let optionsText = null;

      const optionsFactory = optionsContainer => {
        return optionsContainer.map(option => {
          return {
            text: `${optionsText}[${option.id}]`,
            value: option.id
          };
        });
      };

      switch (this.filter.type) {
        case FILTER_TYPE.PAYMENT_STATE:
          optionsText = "PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES";
          returnOptions = optionsFactory(PAYMENT_STATE);
          break;
        case FILTER_TYPE.ORDER_STATUS:
          optionsText = "PAGES.SALES_MANAGEMENT.LIST.ORDER_STATUSES";
          returnOptions = optionsFactory(ORDER_STATUS);
          break;
        case FILTER_TYPE.FIAT:
          returnOptions = [
            { text: "Fiat", value: true },
            { text: "Crypto", value: false }
          ];
          break;
        case FILTER_TYPE.REGULAR:
          returnOptions = this.regularOptions;
          break;
      }
      returnOptions.push(this.allOption);
      return returnOptions;
    }
  },
  methods: {
    getButtonText() {
      const currentValue = this.filter.currentValue;
      const optionItem = this.options.filter(
        option => option.value == currentValue
      );

      return this.$te(optionItem[0].text)
        ? this.$t(optionItem[0].text)
        : optionItem[0].text;
    },
    isOptionActive(value) {
      return this.filter.currentValue === value;
    }
  }
};
</script>
